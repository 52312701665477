import React from "react"
import  GuestLayout  from '../components/Layout/GuestLayout'

const NotFoundPage = () => (
  <GuestLayout>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </GuestLayout>
)

export default NotFoundPage
